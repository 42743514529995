@media screen and (min-device-width:768px){
    .stepsContainer{
        background-color: white;
        padding: 20px;
        border-top-right-radius: 15px;
        height: 100vh;
       
    
    }
    .multi-step-form{
        display: flex;
        
        
    }
    
    .regImg{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        border-bottom-left-radius: 15px;
    }
    .regLeft{
        flex:6;
        background-color: white;
    }
    .regRight{
        flex: 6;
    }
    .leftBox{
        width: 90%;
        height: 97vh;
        margin-left: 3%;
        border-radius: 3%;
        margin-right: 3%;
        margin-top: 1%;
        background-color: rgb(2, 161, 79);;
    }
    .leftBox h2{
        color: white;
        text-align: center;
        width: 100%;
        padding: 20px;
        top: 10%;
        
    }
    .leftBox h4{
        color: white;
        text-align: center;
        margin-top: 2%;
    }
    .pointers{
        margin-left: 28%;
        margin-top: 1%;
    }
    .pointers p{
        color: rgb(48, 48, 48);
        background-color: rgb(255, 255, 255);
        width: 62%;
        padding: 5px;
        border-radius: 10px;

    }
    
    .demoImage{
        width: 70%;
        margin-left: 14%;
        padding: 20px;
        height: 250px;
        border-radius: 35px;
    }
    
    .formTitle{
       
    }
    .step1Wrapper{
        margin-left: 29%;
        margin-top: 3%;
    }
    .fields input,select{
        width: 65%;
        height: 40px;
        border: 1px solid rgb(181, 181, 181);
        border-radius: 10px;
    
    }
    .fields2 input{
        width: 31%;
        height: 40px;
        margin-right: 3%;
        border: 1px solid rgb(181, 181, 181);
        border-radius: 10px;
    
    }
    .formButtons{
        display: flex;
    }
    
    .fields2 select{
        width: 31%;
        height: 40px;
        margin-right: 3%;
        border: 1px solid rgb(181, 181, 181);
        border-radius: 10px;
    
    }
    .fields input:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);
    }
    .fields select:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);;
    }
    .fields2 input:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);;
    }
    .fields2 select:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);;
    }
    .fields,.fields2{
        margin-top: 2%;
    }
    .fields2{
        
        
        display: flex;
    }
    .validateBTN{
        background-color: rgba(255, 255, 255, 0);
        border: none;
        color: orange;
        margin-top: 2%;
    }
    .cont{
        margin-top: 5%;
    }
    .logo{
        width: 30%;
        
        margin-bottom: 3%;
    }
}
@media screen and (max-width:468px){
    .stepsContainer{
        background-color: white;
        padding: 20px;
        border-top-right-radius: 15px;
        height: 100vh;
       
    
    }
    .multi-step-form{
        display: flex;
        flex-direction: column;
        
        
    }
    
    .regImg{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        border-bottom-left-radius: 15px;
    }
    .regLeft{
        flex:6;
        background-color: white;
    }
    .regRight{
        flex: 6;
    }
    .leftBox{
        width: 90%;
        height: 100vh;
        margin-left: 3%;
        border-radius: 3%;
        margin-right: 3%;
        margin-top: 1%;
        background-color: rgb(2, 161, 79);;
    }
    .leftBox h2{
        color: white;
        text-align: center;
        width: 100%;
        padding: 20px;
        top: 10%;
    }
    .leftBox h4{
        color: white;
        text-align: center;
        margin-top: 5%;
    }
    
    .demoImage{
        width: 70%;
        margin-left: 14%;
        padding: 20px;
        height: 150px;
        border-radius: 10%;
    }
    
    .formTitle{
       
    }
    .step1Wrapper{
        margin-left: 29%;
        margin-top: 3%;
    }
    .fields input,select{
        width: 95%;
        height: 40px;
        border: 1px solid rgb(181, 181, 181);
        border-radius: 10px;
    
    }
    .fields select{
        width: 95%;
    }
    .fields2 input{
        width: 61%;
        height: 40px;
        margin-right: 3%;
        border: 1px solid rgb(181, 181, 181);
        border-radius: 10px;
    
    }
    .formButtons{
        display: flex;
    }
    
    .fields2 select{
        width: 61%;
        height: 40px;
        margin-right: 3%;
        border: 1px solid rgb(181, 181, 181);
        border-radius: 10px;
    
    }
    .fields input:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);
    }
    .fields select:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);;
    }
    .fields2 input:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);;
    }
    .fields2 select:focus{
        outline: none;
        border: 1px solid rgb(2, 161, 79);;
    }
    .fields,.fields2{
        margin-top: 2%;
    }
    .fields2{
        
        
        display: flex;
    }
    .validateBTN{
        background-color: rgba(255, 255, 255, 0);
        border: none;
        color: orange;
        margin-top: 2%;
    }
    .cont{
        margin-top: 5%;
    }
    .logo{
        width: 30%;
        
        margin-bottom: 3%;
    }
    .pointers{
        margin-left: 5%;
        margin-top: 1%;
    }
    .pointers p{
        color: rgb(48, 48, 48);
        background-color: rgb(255, 255, 255);
        width: 92%;
        padding: 5px;
        border-radius: 10px;

    }
}


