.minutesTop{
    display: flex;
    justify-content: space-between;
}
.checkb{
    transform: scale(0.4);
    width: 3%;
}
.checkb3{
    transform: scale(1.2);
    width: 3%;
}
.checkb4{
    transform: scale(1.2);
    width: 5%;
    margin-top: -5%;
    margin-right: 3%;
}

.members{
    display: flex;
    align-items: center;
}
.members2{
    display: flex;
    align-items: center;
}

.members p{
    margin-top: 1.1%;
}
.members2 p{
    margin-top: 3%;
    margin-left: 2%;
}
.steps2Container{
    background-color: white;
    padding: 20px;
    border-top-right-radius: 15px;
}
.preciding{
    margin-top: 2%;
}
.preciding select:focus{
    outline: none;
}
.radioButtons input{
    transform: scale(0.4);
    width: 3%;
}
.radioButtons{
    display: flex;
}
.radioButtons label{
    margin-top: 0.8%;
}
.preciding textarea{
    resize: none;
}
.preciding textarea:focus{
    outline: none;

}
.inputField input,select{
    width: 40%;
}
.inputField4 select{
    width: 40%;
    margin-bottom: 2%;

}
.inputField4 select:focus{
    outline: none;
    border: 1px solid rgb(2, 161, 79);
}
.inputField3{
    margin-top: 2%;
}
.inputField3 select{
    width: 43%;
}
.inputField textarea{
    border: 1px solid gray;
    border-radius: 3px;
}
.inputField textarea:focus{
    outline:none;
    

}
.minutesWrapper{
    padding: 20px;
    display: flex;
    
}
.minutesLeft{
    background-color: white;
    flex: 3;
    border-right: 1px solid rgb(223, 223, 223);
    height: 100vh;
    padding: 10px;
}
.minutesRight{
    flex: 9;
    height: 100vh;
    padding-left: 10px;
    overflow-y: scroll;
}
.minutesButton{
    background-color: rgba(255, 255, 255, 0);
    border: none;
    color: rgb(111, 111, 111);
    margin-top: 4%;
    font-weight: 400;
}
.minutesButton:hover{
    color: rgb(2, 161, 79);;

}
.minutesContent{
    margin-left: 3%;
    margin-top: 3%;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(2, 161, 79);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(2, 161, 79);
  }
  .minutesHeader{
    margin-right: 2%;
    display: flex;
    justify-content: space-between;
  }
  .motionWrapper{
    padding: 20px;
}
.inputField5 input{
    width: 95%
}
.inputField5 input:focus{
    outline: none;
    border: 1px solid rgb(2, 161, 79);
}

.ongoingWrapper{
    display: flex;
}
.ongoingLeft{
    padding: 10px;
    flex: 2;
    background-color: white;
    height: 76.4vh;
}
.ongoingRight{
    flex: 8;
    height: 76.4vh;
    background-color: white;
    padding: 10px;
    margin-left: 1%;
    overflow-y: scroll;
}
.ongoingActions{
    flex: 2;
    height: 76.4vh;
    margin-left: 1%;
   
}
.drafts{
    display: flex;
    justify-content: space-between;
    margin-right: 5%;
    align-items: flex-start;
}
.ongoingContainer{
    background-color: white;
    padding: 10px;
}
.action{
    transition-duration: 0.4s;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    margin-top: 3%;
}
.action:hover{
    margin-left: 5px;
    color: rgb(2, 161, 79);

}
.newMinutesWrapper{
    display: flex;
}
.newMinutesWrapperLeft{
    flex: 10;
}
.newMinutesWrapperRight{
    flex: 2;
    margin-left: 1%;
}
.recommendationWrapper{
    padding: 20px;
}

.invitationWrapper{
    display: flex;
    margin-top: 2%;
}
.invitationLeft{
    padding: 20px;
    height: 100vh;
    flex: 2;
    background-color: rgb(41, 46, 44);
}
.invitationLeft h4{
    color: white;
}
.invitationRight{
    height: 100vh;
    
    flex: 10;
    background-color: white;
    margin-left: 1%;
    padding: 20px;
}
.newInvitationBTN{
    background-color: white;
    border: none;
    padding: 5px;
    border-radius: 5px;
  
}
.invitationFieldsTop{
    display: flex;
    
}
.inputField6{
    flex: 4;
    margin-left: 2%;
    margin-top: 2%;
}
.inputField6 select,input{
    width: 100%;
    border: 1px solid grey;
}
.inputField6 select:focus{
    outline: none;
    border: 1px solid rgb(2, 161, 79);
}
.inputField6 input:focus{
    outline: none;
    border: 1px solid rgb(2, 161, 79);
}
.membersInvite{
  
    margin-top: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.members{
    
    align-items: center;
    flex-basis: 25%; /* Set each member to occupy 25% of the container width */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  /* Adjust spacing as needed */
}
.edi-height {
    min-height: 200px;
  }
  .demo-editor {
    padding: 0 15px;
    margin: 0;
  }
  .invitations button{
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border: none;
    padding: 0;
  }
  .invitations{
    margin-top: 4%;
  }
  .invitationList{
    margin-top: 10%;
  }
  .pending{
    text-align: right;
    color: red;

  }
  .approved{
    text-align: right;
    color: green;

  }
  .meetingTitle{
    margin-top: 2%;
    text-align: center;
    color:white;
    font-weight: 500;
    padding: 5px;
    background-color: rgb(4, 4, 33);
  }
  .invitationArea:focus{
    outline: none;

  }
  .displayInvitation{
    width: 70%;
    margin-left: 13%;
    background-color: white;
    padding: 10px;
  }
  .invitationButtons{
    width: 40%;
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
  }
  .approveContainer{
    padding: 10px;
  }
  .codeInput{
    width: 30%;
    margin-top: 2%;
    height: 30px;
  }
  .footer{
    display: flex;
  }
  .bi-circle-fill{
    margin-right: 5px;
    font-size: 8px;
    color: rgb(3, 208, 3);
    
  }
  .inputFileField input{
    border: none;
  }
  .fileLists{
    display: flex;
    flex-direction: column;
  }
  .fileList{
    margin-top: 3%;
    text-decoration: none;
    color: rgb(66, 66, 66);
  }

