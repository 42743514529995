.companyOverview{
    margin-top: 3%;
    margin-left: 2%;
    width: 65%;
    border: 1px solid rgb(194, 194, 194);
    padding: 10px;
    border-radius: 3px;
}
.companyDetail{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(194, 194, 194);
    padding: 5px;
}
.cardWrapper{
    display: flex;
    margin-top: 2%;

}
.adminCard{
    flex: 4;
}
.directorCard{
    flex:4;

}
.memberCard{
    flex: 4;
}
.transferBtn{
    background-color: rgb(2, 161, 79) ;
    color: white;
    border: none;
    padding: 6px;
    margin-bottom: 2%;
   
}
.transferBtn2{
    background-color: rgb(255, 0, 0) ;
    color: white;
    border: none;
    padding: 6px;
    margin-bottom: 2%;
    margin-top: 4%;
}
.Newtransfer{
    padding: 20px;
}
.inputField select,input{
    width: 80%;
    height: 40px;
    border: 1px solid gray;
    color: gray;
    border-radius: 3px;
}
.inputField{
    margin-top: 3%;
}
.inputField select:focus{
    outline: none;
    border: 1px solid rgb(2, 161, 79);

}
.inputField input:focus{
    outline: none;
    border: 1px solid rgb(2, 161, 79);

}